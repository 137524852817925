<template>
	<div class="diy_edit page_i_want_to_make_a_reservation" id="i_want_to_make_a_reservation_edit">
		<div class='warp'>
			<div class='container'>
				<div class='row'>
					<div class="col-12 col-md-6">
						<div class="diy_title">
							<span>房号:
							</span>
						</div>
						<!-- 文本 -->
						<div class="diy_field diy_text">
							<input type="text" id="form_room_number" v-model="form['room_number']" placeholder="请输入房号" v-if="(form['room_number'] && $check_field('set','room_number')) || (!form['room_number'] && $check_field('add','room_number'))"  :disabled="disabledObj['room_number_isDisabled']"/>
							<span v-else-if="$check_field('get','room_number')">{{ form[''] }}</span>
						</div>
					</div>
					<div class="col-12 col-md-6">
						<div class="diy_title">
							<span>类型:
							</span>
						</div>
						<!-- 文本 -->
						<div class="diy_field diy_text">
							<input type="text" id="form_type" v-model="form['type']" placeholder="请输入类型" v-if="(form['type'] && $check_field('set','type')) || (!form['type'] && $check_field('add','type'))"  :disabled="disabledObj['type_isDisabled']"/>
							<span v-else-if="$check_field('get','type')">{{ form[''] }}</span>
						</div>
					</div>
					<div class="col-12 col-md-6">
						<div class="diy_title">
							<span>图片    :
							</span>
						</div>
						<!-- 文本 -->
						<div class="diy_field diy_text">
							<input type="text" id="form_picture" v-model="form['picture']" placeholder="请输入图片    " v-if="(form['picture'] && $check_field('set','picture')) || (!form['picture'] && $check_field('add','picture'))"  :disabled="disabledObj['picture_isDisabled']"/>
							<span v-else-if="$check_field('get','picture')">{{ form[''] }}</span>
						</div>
					</div>
					<div class="col-12 col-md-6">
						<div class="diy_title">
							<span>价格:
							</span>
						</div>
						<!-- 文本 -->
						<div class="diy_field diy_text">
							<input type="text" id="form_price" v-model="form['price']" placeholder="请输入价格" v-if="(form['price'] && $check_field('set','price')) || (!form['price'] && $check_field('add','price'))"  :disabled="disabledObj['price_isDisabled']"/>
							<span v-else-if="$check_field('get','price')">{{ form[''] }}</span>
						</div>
					</div>
					<div class="col-12 col-md-6">
						<div class="diy_title">
							<span>使用状态:
							</span>
						</div>
						<!-- 文本 -->
						<div class="diy_field diy_text">
							<input type="text" id="form_use_status" v-model="form['use_status']" placeholder="请输入使用状态" v-if="(form['use_status'] && $check_field('set','use_status')) || (!form['use_status'] && $check_field('add','use_status'))"  :disabled="disabledObj['use_status_isDisabled']"/>
							<span v-else-if="$check_field('get','use_status')">{{ form[''] }}</span>
						</div>
					</div>
					<div class="col-12 col-md-6">
						<div class="diy_title">
							<span>备注:
							</span>
						</div>
						<!-- 多文本 -->
						<div class="diy_field diy_desc">
							<textarea id="form_remarks" v-model="form['remarks']" v-if="(form['remarks'] && $check_field('set','remarks')) || (!form['remarks'] && $check_field('add','remarks'))" :disabled="disabledObj['remarks_isDisabled']" />
							<span v-else-if="$check_field('get','remarks')">{{ form[''] }}</span>
						</div>
					</div>
					<div class="col-12 col-md-6">
						<div class="diy_title">
							<span>用户编号:
							</span>
						</div>
						<div class="diy_field diy_down">
							<select id="form_user_number" :disabled="disabledObj['user_number_isDisabled']" v-model="form['user_number']" v-if="(form['user_number'] && $check_field('set','user_number')) || (!form['user_number'] && $check_field('add','user_number'))" >
								<option v-for="o in list_user_user_number" :value="o['user_id']">
									{{o['nickname'] + '-' + o['username']}}
								</option>
							</select>
							<span v-else-if="$check_field('get','user_number')">{{ form['user_number'] }}</span>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-12">
						<div class="btn_box">
							<button class="btn_submit" @click="submit()">提交</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import mixin from "@/mixins/page.js";
	export default {
		mixins: [mixin],
		components: {},
		data() {
			return {
				url_get_obj: "~/api/i_want_to_make_a_reservation/get_obj?",
				url_add: "~/api/i_want_to_make_a_reservation/add?",
				url_set: "~/api/i_want_to_make_a_reservation/set?",

				// 登录权限
				oauth: {
					"signIn": true,
					"user_group": []
				},

				// 查询条件
				query: {
					"room_number": "",
					"type": "",
					"picture": "",
					"price": "",
					"use_status": "",
					"remarks": "",
					"user_number": 0,
					"i_want_to_make_a_reservation_id": 0
				},

				obj: {
					"room_number":'', // 房号
					"type":'', // 类型
					"picture":'', // 图片    
					"price":'', // 价格
					"use_status":'', // 使用状态
					"remarks":'', // 备注
					"user_number": 0, // 用户编号
					"i_want_to_make_a_reservation_id": 0
				},

				// 表单字段
				form: {
					"room_number":'', // 房号
					"type":'', // 类型
					"picture":'', // 图片    
					"price":'', // 价格
					"use_status":'', // 使用状态
					"remarks":'', // 备注
					"user_number": 0, // 用户编号
					"i_want_to_make_a_reservation_id": 0
				},
				disabledObj:{
					"room_number_isDisabled": false,
					"type_isDisabled": false,
					"picture_isDisabled": false,
					"price_isDisabled": false,
					"use_status_isDisabled": false,
					"remarks_isDisabled": false,
					"user_number_isDisabled": false,
				},

				// ID字段
				field: "i_want_to_make_a_reservation_id"
			}
		},
		methods: {

			/**
			 * 修改文件
			 * @param { Object } files 上传文件对象
			 * @param { String } str 表单的属性名
			 */
			change_file(files, str) {
				var form = new FormData();
				form.append("file", files[0]);
				this.$post("~/api/i_want_to_make_a_reservation/upload?", form, (res) => {
					if (res.result) {
						this.form[str] = res.result.url;
					} else if (res.error) {
						this.$toast(res.error.message);
					}
				});
			},

			/**
			 * 获取对象后获取缓存表单
			 * @param {Object} json
			 * @param {Object} func
			 */
			get_obj_before(param){
				var form = $.db.get("form");
				if (form) {
					this.obj = $.push(this.obj ,form);
					this.form = $.push(this.form ,form);
				}
				var arr = []
				for (let key in form) {
					arr.push(key)
				}
				for (var i=0;i<arr.length;i++){
					this.disabledObj[arr[i] + '_isDisabled'] = true
				}
				return param;
			},

			/**
			 * 获取对象后获取缓存表单
			 * @param {Object} json
			 * @param {Object} func
			 */
			get_obj_after(json ,func){
				var form = $.db.get("form");
				var obj = Object.assign({} ,form ,this.obj);
				if (form) {
					this.obj = $.push(this.obj ,obj);
				}
				if (form) {
					this.form = $.push(this.form ,form);
				}
				if(func){
					func(json);
				}
			}

		},
		created() {
		}
	}
</script>

<style>
</style>
